<template>
  <div class="b-page">
    <div class="b-page__content">
      <h1 class="main-page">Документы</h1>
      <div class="container block-margin-bottom">
        <div class="mini_banners">
          <div class="container block-margin">
            <h2>Типовой договор о проведении ТО</h2>
            <div class="row docs">
              <div class="item col col-md-4">
                <div class="image">
                  <a href="/files/карточка%20организации%20ТЕХУСПЕХ.pdf"></a>
                  <a href="/files/типовой%20договор%20о%20проведении%20ТО.doc"></a>
                  <img src="/files/dog.PNG" alt="Типовой договор о проведении ТО" title="Типовой договор о проведении ТО">

                </div>
                <div class="data">
                  <div class="info smaller">
                    <div class="name">
                      <a class="aprimary" href="/files/типовой%20договор%20о%20проведении%20ТО.doc">Типовой договор о проведении ТО</a>
                    </div>
                  </div>
                  <div class="dl">
                    <a href="/files/типовой%20договор%20о%20проведении%20ТО.doc">Скачать: DOC</a>
                  </div>
                </div>

              </div>
            </div>
            <h2>Документация для пользователей</h2>
            <div class="row docs">
              <div class="item col col-md-4">
                <div class="image">
                  <a href="/files/карточка%20организации%20ТЕХУСПЕХ.pdf"></a>
                  <img src="/files/cartochka.PNG" alt="ИНФОРМАЦИОННАЯ КАРТОЧКА" title="ИНФОРМАЦИОННАЯ КАРТОЧКА">

                </div>
                <div class="data">
                  <div class="info smaller">
                    <div class="name">
                      <a class="aprimary" href="/files/карточка%20организации%20ТЕХУСПЕХ.pdf">ИНФОРМАЦИОННАЯ КАРТОЧКА</a>
                    </div>
                  </div>
                  <div class="dl">
                    <a href="/files/карточка%20организации%20ТЕХУСПЕХ.pdf">Скачать: PDF</a>
                  </div>
                </div>

              </div>
            </div>
            <h2>
              Учредительные документы
            </h2>
            <div class="row docs" >
              <div class="item col col-md-4">
                <div class="image">
                  <a href="/files/Приказ%20№1.pdf"></a>
                  <img src="/files/prikaz.PNG" alt="ПРИКАЗ №1"
                       title="ПРИКАЗ №1">
                </div>
                <div class="data">
                  <div class="info smaller">
                    <div class="name">
                      <a class="aprimary" href="/files/Приказ%20№1.pdf">ПРИКАЗ
                        №1</a>
                    </div>
                  </div>
                  <div class="dl">
                    <a href="/files/Приказ%20№1.pdf">Скачать: PDF</a>
                  </div>
                </div>

              </div>
              <div class="item col col-md-4" >
                <div class="image">
                  <a href="/files/Устав%20ТехУспех.pdf"></a>
                  <img src="/files/ustav.PNG" alt="УСТАВ" title="УСТАВ">
                </div>
                <div class="data">
                  <div class="info smaller">
                    <div class="name">
                      <a class="aprimary" href="/files/Устав%20ТехУспех.pdf">УСТАВ</a>
                    </div>
                  </div>
                  <div class="dl">
                    <a href="/files/Устав%20ТехУспех.pdf">Скачать: PDF</a>
                  </div>
                </div>

              </div>
              <div class="item col col-md-4" >
                <div class="image">
                  <a href="/files/Заявление%20о%20переходе%20на%20упрощенную%20систему%20налдогообложения%20ТехУспех.pdf"></a>
                  <img src="/files/uvedomlenie.PNG" alt="УВЕДОМЛЕНИЕ"
                       title="УВЕДОМЛЕНИЕ">
                </div>
                <div class="data">
                  <div class="info smaller">
                    <div class="name">
                      <a class="aprimary" href="/files/Заявление%20о%20переходе%20на%20упрощенную%20систему%20налдогообложения%20ТехУспех.pdf">УВЕДОМЛЕНИЕ</a>
                    </div>
                  </div>
                  <div class="dl">
                    <a href="/files/Заявление%20о%20переходе%20на%20упрощенную%20систему%20налдогообложения%20ТехУспех.pdf">Скачать: PDF</a>
                  </div>
                </div>

              </div>
              <div class="item col col-md-4" >
                <div class="image">
                  <a href="/files/СВИДЕТЕЛЬСТВО%20ФНС%20ОТ%2014.03.2017%20ТЕХУСПЕХ.pdf"></a>
                  <img src="/files/svidetelstvo.PNG" alt="ПОСТАНОВКА В НАЛОГОВОЙ"
                       title="ПОСТАНОВКА В НАЛОГОВОЙ">

                </div>
                <div class="data">
                  <div class="info smaller">
                    <div class="name">
                      <a class="aprimary" href="/files/СВИДЕТЕЛЬСТВО%20ФНС%20ОТ%2014.03.2017%20ТЕХУСПЕХ.pdf">ПОСТАНОВКА
                        В НАЛОГОВОЙ</a>
                    </div>
                  </div>
                  <div class="dl">
                    <a href="/files/СВИДЕТЕЛЬСТВО%20ФНС%20ОТ%2014.03.2017%20ТЕХУСПЕХ.pdf">Скачать: PDF</a>
                  </div>
                </div>

              </div>
              <div class="item col col-md-4" >
                <div class="image">
                  <a href="/files/Лист%20записи%20Единого%20государственного%20реестра%20юридических%20лиц%20ООО%20ТехУспех.pdf"></a>
                  <img src="/files/egryl.PNG" alt="ЕГРЮЛ" title="ЕГРЮЛ">

                </div>
                <div class="data">
                  <div class="info smaller">
                    <div class="name">
                      <a class="aprimary" href="/files/Лист%20записи%20Единого%20государственного%20реестра%20юридических%20лиц%20ООО%20ТехУспех.pdf">ЕГРЮЛ</a>
                    </div>
                  </div>
                  <div class="dl">
                    <a href="/files/Лист%20записи%20Единого%20государственного%20реестра%20юридических%20лиц%20ООО%20ТехУспех.pdf">Скачать: PDF</a>
                  </div>
                </div>

              </div>
            </div>

            <h2>Лицензии и сертификаты</h2>
            <div class="row docs">
              <div class="item col col-md-4">
                <div class="image">
                  <a href="/files/Аттестат%20ТехУспех%202016год.pdf"></a>
                  <img src="/files/atestat.PNG" alt="АТТЕСТАТ" title="АТТЕСТАТ">

                </div>
                <div class="data">
                  <div class="info smaller">
                    <div class="name">
                      <a class="aprimary" href="/files/Аттестат%20ТехУспех%202016год.pdf">АТТЕСТАТ</a>
                    </div>
                  </div>
                  <div class="dl">
                    <a href="/files/Аттестат%20ТехУспех%202016год.pdf">Скачать: PDF</a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Docs"
}
</script>

<style scoped>

</style>